@import "scss/variables";
@import "~@fortawesome/fontawesome-free/css/all.css";
//html, body { height: 100%; }

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
}

body {
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(./assets/fonts/2fcrYFNaTjcS6g4U3t-Y5ZjZjT5FdEJ140U2DJYC3mY.woff2) format('woff2');
  }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  // Standard property for OpenType font features
  font-feature-settings: 'liga'; // Example feature for ligatures

  // Vendor prefixes for compatibility
  -webkit-font-feature-settings: 'liga'; // Chrome, Safari, and newer versions of Opera
  -moz-font-feature-settings: 'liga'; // Firefox (older versions)
  -ms-font-feature-settings: 'liga'; // Internet Explorer and Edge (older versions)
  -o-font-feature-settings: 'liga'; // Older versions of Opera

  -webkit-font-smoothing: antialiased; // WebKit browsers
}

* {
  margin: 0;
}

html,
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  line-height: 140%;
  color: $black-color !important;
}

body {
  margin: 0;
}

button.mdnra-button {
  &.mat-stroked-button,
  &.mat-flat-button {
    font: 400 16px "Roboto", "Helvetica Neue", sans-serif;
    border-color: black;
    height: 40px;
    border-radius: 20px;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #f4e8e3 inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #f4e8e3 inset; /*your box-shadow*/
  -webkit-text-fill-color: #333;
}

.snackbar-content {
  align-items: center;
  color: $snackbar-font;

  .mat-simple-snackbar-action {
    float: right;
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

