$black-color: #000000;
$white-color: #ffffff;

$grey-lightest-color: #f6f6f6;
$grey-lighter-color: #eeeeee;
$grey-light-color: #dddddd;
$grey-light-less-color: #cccccc;
$grey-color: #999999;
$grey-dark-color: #666666;
$grey-darker-color: #555555;

$green-color: #60bcb1;
$green-dark-color: #159887;

$brown-light-color: #ce9a86;
$brown-color: #b46547;

$home-green: #1a796d;
$therapy-green: $green-color;
$therapy-head-button: $green-color;
$wellbeing-head-button: #a6969b;
$knowledge-head-button: $brown-light-color;
$median-active: $brown-color;
$x-button-green: #03a793;
$wellbeing-green: #a6969b;
$wellbeing-question: #d3cbcd;
$knowledge-blue: $brown-light-color;
$knowledge-blue-card: #e6ccc2;
$target-card: #d0d0d1;
$home-page-card: $grey-light-less-color;
$main-content-background: $grey-lightest-color;
$link: $brown-color;
$therapy-card: #b1ddd7;
$subtitle-gray: $grey-darker-color;
$footer-gray: #333333;
$tree-circle-empty: #159887;
$tree-circle-inner: #176d62;
$snackbar-font: $black-color;
$snackbar-error: #f8d6da;
$snackbar-warning: #fff3cd;
$snackbar-info: #bbe0eb;
$peak-eye-color: #454545;
$red: #ff0000;
